const Continents = [
    { key: 1, value: "인물" },
    { key: 2, value: "풍경" },
    { key: 3, value: "패션" },
    { key: 4, value: "행사" },
    { key: 5, value: "제품" },
    { key: 6, value: "음식" },
    { key: 7, value: "스포츠" },
 
];

export default Continents;